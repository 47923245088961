* {
    margin: 0;
    padding: 0;
}



.wrap-all {
    padding-top: 10vh;
    min-height: 100vh;
    background-color: #F28595;

    >.container {
        background: #fff;
        padding: 4vh 4vw;
        border-radius: 40px;
    }

    .MyNav {
        display: flex;
        justify-content: space-between;
    }
}